import React, { useState, useRef } from "react"
import { Box } from "theme-ui"
import Image from "gatsby-image"

export default function ScalledImage({ img }) {
  const [openBigImage, setOpenBigImage] = useState(false)
  const [xys, setXys] = useState([0, 0, 1])
  const ref = useRef({})

  const calc = (x, y) => [
    -(y - ref.current.offsetHeight) / 20,
    (x - ref.current.offsetWidth) / 20,
    1.1,
  ]

  const handleEnter = ({ clientX: x, clientY: y }) => {
    setXys(calc(x, y))
    setOpenBigImage(true)
  }
  const handleLeave = () => {
    setOpenBigImage(false)
    setXys([0, 0, 1])
  }

  return (
    <Box
      ref={ref}
      onMouseMove={handleEnter}
      onMouseLeave={handleLeave}
      sx={{
        height: "fit-content",
        boxShadow: "0 0 5px rgba(0,0,0,.1)",
        p: 2,
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
        }}
      >
        {openBigImage && (
          <Box
            sx={{
              transition: "all ease-in",
              width: "100%",
              position: "absolute",
              zIndex: 1,
              transform: `translate(-${xys[1] / 1.5}px, ${xys[0]}px)`,
            }}
          >
            <Box
              sx={{
                transform: "scale(1.2)",
              }}
            >
              <Image fluid={img?.childImageSharp?.fluid || {}} />
            </Box>
          </Box>
        )}
        <Image style={{}} fluid={img?.childImageSharp?.fluid || {}} />
      </Box>
    </Box>
  )
}
