import React from "react"
import Image from "gatsby-image"
import PageLayout from "./page-layout"
import { Link as GLink, graphql } from "gatsby"
import { Box, Grid, Styled, Flex, Link } from "theme-ui"
import ScalledImage from "../components/scaled-image"
import { globe } from "react-icons-kit/iconic/globe"
import { androidDocument } from "react-icons-kit/ionicons/androidDocument"
import { Icon } from "react-icons-kit"
import Wrapper from "../components/wrapper"
import UnderLine from "../components/under-line"

export default function ServiceLayout({
  data: {
    markdownRemark: { frontmatter, html },
    nextProject,
    prevProject,
  },
}) {
  const {
    bg,
    logo,
    img,
    title,
    subTitle,
    width,
    lang,
    footerImgs,
    website,
    profile,
    logoShadow,
    bgColor,
  } = frontmatter

  return (
    <PageLayout
      showBreadcrumbs={false}
      backgroundColor="#000"
      opacity={0.3}
      mainImage={bg?.childImageSharp?.fluid || {}}
      title={title}
    >
      <Grid gap={0} columns={[1, null, 2]}>
        <ScalledImage img={img} />
        <Flex
          backgroundColor={bgColor}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            py: 4,
            px: "15%",
            mx: "auto",
            textAlign: "center",
          }}
        >
          {logo ? (
            <Box
              sx={{
                width: width || 352,
                mb: 2,
                p: "6px",
                boxShadow: logoShadow,
              }}
            >
              <Image
                style={{
                  boxShadow: logoShadow,
                }}
                fluid={logo?.childImageSharp?.fluid || {}}
              />
            </Box>
          ) : null}
          <Styled.h2>{subTitle}</Styled.h2>
          <UnderLine />
          <Box
            sx={{
              p: { fontWeight: "600" },
              h4: { fontSize: "1.1em", color: "#333" },
            }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {website && (
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={`http://${website}`}
              sx={{
                p: 35,
                mt: 2,
                width: "100%",
                color: "#222",
                textDecoration: "none",
                bg: "#f5f5f5",
                ":hover": {
                  color: "primary",
                  backgroundColor: "white",
                  boxShadow: "2px 2px 10px #33333311",
                },
              }}
            >
              <Box sx={{ color: "#8224e3" }}>
                <Icon icon={globe} size={38} />
              </Box>
              <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
                {website}
              </p>
            </Box>
          )}
          {profile && (
            <Box
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={"/img/" + profile.relativePath}
              sx={{
                p: 35,
                mt: 2,
                width: "100%",
                color: "#222",
                textDecoration: "none",
                bg: "#f5f5f5",
                ":hover": {
                  color: "primary",
                  backgroundColor: "white",
                  boxShadow: "2px 2px 10px #33333311",
                },
              }}
            >
              <Box sx={{ color: "#8224e3" }}>
                <Icon icon={androidDocument} size={38} />
              </Box>
              <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
                {lang === "ar" ? "البروفايل" : "Profile"}
              </p>
            </Box>
          )}
        </Flex>
      </Grid>
      {footerImgs?.map(({ image }, index) => (
        <Box
          sx={{ mb: footerImgs.length > 1 && 3 }}
          key={image?.childImageSharp?.src || index}
        >
          <Image fluid={image?.childImageSharp?.fluid || {}} />
        </Box>
      ))}
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.02)",
        }}
      >
        <Wrapper>
          <Flex
            sx={{
              justifyContent: "space-between",
              py: 70,
              alignItems: "center",
            }}
          >
            <ProjectLink
              title={prevProject?.frontmatter?.title}
              to={prevProject?.fields?.slug}
            />
            <GalleryIcon />
            <ProjectLink
              dir="ltr"
              title={nextProject?.frontmatter?.title}
              to={nextProject?.fields?.slug}
            />
          </Flex>
        </Wrapper>
      </Box>
    </PageLayout>
  )
}

const GalleryIcon = () => (
  <Flex as="a" href="#main">
    <Box
      sx={{
        border: "2px solid #ccc",
        height: 44,
        width: "16px",
        borderRadius: 2,
      }}
    />
    <Box sx={{ mx: "1px" }}>
      <Box
        sx={{
          border: "2px solid #ccc",
          height: "21px",
          width: "16px",
          mb: "2px",
          borderRadius: 2,
        }}
      />
      <Box
        sx={{
          border: "2px solid #ccc",
          height: "21px",
          width: "16px",
          borderRadius: 2,
        }}
      />
    </Box>
    <Box
      sx={{
        border: "2px solid #ccc",
        height: 44,
        width: "16px",
        borderRadius: 2,
      }}
    />
  </Flex>
)
const ProjectLink = ({ title, to, dir }) => (
  <Box dir={dir} sx={{ minWidth: 150 }}>
    <Link
      sx={{
        color: "text",
        py: 5,
        px: 2,
        position: "relative",
        "::before": {
          transition: "all cubic-bezier(0.39, 0.58, 0.57, 1) 1s",
          content: '""',
          height: "2px",
          display: to && "block",
          mb: "15px",
          width: 50,
          top: 0,
          backgroundColor: "#0000001a",
        },
        ":hover": {
          textDecoration: "none",
          "::before": {
            width: 100,
            backgroundColor: "primary",
          },
        },
      }}
      as={GLink}
      to={to}
    >
      {title}
    </Link>
  </Box>
)

export const postQuery = graphql`
  fragment profileSrc on File {
    relativePath
  }
  query($slug: String!, $next: String, $prev: String) {
    nextProject: markdownRemark(fields: { slug: { eq: $next } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    prevProject: markdownRemark(fields: { slug: { eq: $prev } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subTitle
        bgColor
        logoShadow
        width
        lang
        description
        bg {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        logo {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        footerImgs {
          image {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        profile {
          ...profileSrc
        }
        website
        type
      }
    }
  }
`
